// development

// const baseUrl = 'http://localhost:4000'
const baseUrl = 'https://api.bctsl.de'

module.exports = {
    QR_IMAGE_PATHS: `${baseUrl}/uploads/qrcodes`,
    IMAGE_PATH: `${baseUrl}/uploads`,
    API_ENDPOINT: `${baseUrl}/api`,
    PRODUCT_DETAILS_URL: `${baseUrl}/product-details`,

    localServer: {
        addRfid: 'http://localhost:9100',
        readRfid: 'http://localhost:9191',
    },

    MAP_API_KEY: `AIzaSyC8g4r3hMbuXfFPpkdHyeknLwE_0gnNQQc`,
}
